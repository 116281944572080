footer {
  position: relative !important;
    bottom: 0;
    left: 0;
    right: 0rem;
    width: 100%;
    
}

a {
  color: inherit;
}

.text {
  color:#8D3DAF;
}

body {
  background-color: #242B2E;
}

img {
    max-width: 600px;

}

body {
    background-color: white;
}

ul {
  padding-inline-start: 0px;
}

/* .navbar {
  position: relative !important;
  top: 0px;
  left: 0px;
  
} */

button {
  align-items: center;
}
